import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`1/2 Mile Run`}</p>
    <p>{`15 Burpees`}</p>
    <p>{`1000M Row`}</p>
    <p>{`25 Box Jumps (M-24/W-20)`}</p>
    <p>{`1/4 Mile Run`}</p>
    <p>{`30 Burpees`}</p>
    <p>{`500M Row`}</p>
    <p>{`50 Box Jumps`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      